import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../../../../../components/Layout';
import SEO from '../../../../../components/seo';
import Hero from '../../../../../components/Hero';
import {
	HighlightedParagraph,
	HighlightedTitle,
} from '../../../../../components/highlightedText';
import Subnavigation from '../../../../../components/Subnavigation';
import PersonBadge from '../../../../../components/PersonBadge';
import Breadcrumb from '../../../../../components/Breadcrumb';
import Content from '../../../../../components/Content';
import Block from '../../../../../components/Block';
import {getSectorSubpageByKeys} from '../../../../../selectors/data';
import {getTranslated, lang} from '../../../../../helpers/lang';

const StructuralDeformations = ({pageContext}) => {
	const data = useStaticQuery(graphql`
		query {
			background: file(relativePath: {eq: "pages/work/sectors/bridge.jpg"}) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	const pageData = getSectorSubpageByKeys(
		pageContext.parent.key,
		pageContext.subpage.key
	);

	return (
		<Layout theme={pageData.theme}>
			<SEO title={pageData.title} lang={lang} />
			<Hero backgroundImageFluid={data.background.childImageSharp.fluid}>
				<Breadcrumb crumbs={pageContext.crumbs} />
				<HighlightedTitle>
					{getTranslated(pageData.title, lang)}
				</HighlightedTitle>
			</Hero>
			<Block light>
				<Content>
					<Subnavigation>
						<PersonBadge
							note={pageData.contactNote}
							email={pageData.contactPerson}
						/>
					</Subnavigation>
				</Content>
			</Block>
		</Layout>
	);
};

StructuralDeformations.propTypes = {
	pageContext: PropTypes.object.isRequired,
};

export default StructuralDeformations;
